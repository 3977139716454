import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import {
  Container,
  Col,
  Row,
  Media
} from 'reactstrap';
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
  EmailShareButton,
  EmailIcon
} from 'react-share';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SideBar from '../components/SideBar';
import Title from '../components/UIComponents/Title';
import TriDymeIcon from '../media/TriDymeIconV1.svg';
import SEO from '../components/SEO';
import locales from '../constants/locales';

const {
  REACT_COMPANY_NAME,
} = process.env;

class DocsPost extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.collapseSection = this.collapseSection.bind(this);
    this.state = { collapse: '' };
  }

  toggle(menu) {
    if (menu === this.state.collapse) {
      this.setState({ collapse: '' });
    } else {
      this.setState({ collapse: menu });
    }
  }

  collapseSection(section, collapse) {
    if (section === collapse) {
      return true;
    }
    return false;
  }

  render() {
    const { pageContext: { locale }, data } = this.props;
    const post = data.markdownRemark;
    const {
      collapse
    } = this.state;
    return (
      <Layout locale={locale}>
        <SEO
          title={`TriDyme | ${post.frontmatter.title}`}
          lang={locale}
          url={locales[locale].default
            ? `/${post.fields.slug}`
            : `/${locales[locale].path}${post.fields.slug}`}
          description={post.frontmatter.description}
          author={post.frontmatter.author}
        />
        <Container className="docPostsContainer">
          <Row>
            <Title
              icon={TriDymeIcon}
              title={`TriDyme Documentation`}
            />
          </Row>
          <hr />
          <Row>
            <Col md={3}>

              {/* <hr /> */}
              <SideBar
                locale={locale}
                toggle={this.toggle}
                collapseSection={this.collapseSection}
                collapse={collapse}
              />
            </Col>
            <Col md={9}>
              <div className="main" dangerouslySetInnerHTML={{ __html: post.html }} />
              <div className="socialFooter">
                <br />
                <Row className="innerSocialFooter">
                  <Col xs={4} className="shareSocial">
                    {/* <p>Retrouvez nous sur:</p> */}
                  </Col>
                  <Col xs={1}>
                    <FacebookShareButton
                      url={`https://www.tridyme.com${post.fields.slug}`}
                      className="socialIcon"
                      children={
                        <FacebookIcon
                          size={30}
                          round={true}
                          iconBgStyle={{ fill: '#007bff' }}
                        />
                      }
                    />
                  </Col>
                  <Col xs={1}>
                    <LinkedinShareButton
                      url={`https://www.tridyme.com${post.fields.slug}`}
                      className="socialIcon"
                      children={
                        <LinkedinIcon
                          size={30}
                          round={true}
                          iconBgStyle={{ fill: '#007bff' }}
                        />
                      }
                    />
                  </Col>
                  <Col xs={1}>
                    <TwitterShareButton
                      url={`https://www.tridyme.com${post.fields.slug}`}
                      className="socialIcon"
                      children={
                        <TwitterIcon
                          size={30}
                          round={true}
                          iconBgStyle={{ fill: '#007bff' }}
                        />
                      }
                    />
                  </Col>
                  <Col xs={1}>
                    <EmailShareButton
                      url={`https://www.tridyme.com${post.fields.slug}`}
                      className="socialIcon"
                      children={
                        <EmailIcon
                          size={30}
                          round={true}
                          iconBgStyle={{ fill: '#007bff' }}
                        />
                      }
                    />
                  </Col>
                  <Col xs={4} />
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </Layout>
    )
  }
}

DocsPost.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired
  }).isRequired
}

export default DocsPost;

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date(formatString: "DD MMMM, YYYY")
        author
        language
        description
        featuredImage {
          publicURL
        }
      }
      fields {
        slug
      }
    }
  }
`
